* {
  margin: 0;
  padding: 0;
  border: none;
  font-style: normal;
  font-variant: normal;
}

.app-container {
  min-height: 100vh;
  width: 100%;
  font-family: bm-regular;
}
